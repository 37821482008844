import React from 'react'
import logo from './images/logo.png'
import './footer.css'

const Footer = () => {

    return (
        <div className='footer'>
            <img src = {logo} alt = 'Warwick Entrepreunuers' />
        </div>
    )
}

export default Footer